.back-icon {
  cursor: pointer;
  margin-bottom: 1rem;
  color: rgb(84, 83, 83);
  &:hover {
    color: var(--primaryColor);
  }
}

.back-icon.disabled {
  opacity: 0.5;
  pointer-events: none;
}

select:disabled {
  opacity: 0.5 !important;
}

a {
  text-decoration: none;
  color: var(--primaryColor);
}

.login-btn {
  background-color: var(--primaryColor);
  color: #fff !important;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  padding: 12px;
  &:hover {
    filter: saturate(0.7);
  }
}

.card-header {
  margin-top: -1px;
  background-color: var(--primaryColor);
  color: #fff !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.input-group-prepend {
  margin-right: -3px;
}

.custom-select {
  padding-left: 4px;
  padding-right: 8px;
}

.error-message {
  color: red;
  padding-bottom: 1rem;
}

.sidebar-nav {
  scrollbar-width: auto !important;
  scrollbar-color: #303c54 #3c4b64 !important;
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #303c54 !important;
  }
  &::-webkit-scrollbar-track {
    background: #3c4b64 !important;
  }
}
