:root {
  --primaryColor: #51f8a8;
  --dangerColor: #d10714;
  --sidebarColor: rgb(0, 26, 76);
  --colorBackground: #efeeec;
  --textInPrimaryBackground: #222;
  --apexDotColor: rgb(0, 26, 76);
  --checkColor: #fff;
  --apexBorderColor: rgb(0, 26, 76);
  --messageCardColor: rgb(0, 26, 76);
  --envelopeColor: #51f8a8;
  --estimatedDelivaryCardColor: #51f8a8;
}
